import { render, staticRenderFns } from "./CustomContact.vue?vue&type=template&id=6796da55"
import script from "./CustomContact.vue?vue&type=script&lang=js"
export * from "./CustomContact.vue?vue&type=script&lang=js"
import style0 from "./CustomContact.vue?vue&type=style&index=0&id=6796da55&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports