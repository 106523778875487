<template>
  <div class="row">
    <div class="col-lg-12">
      <el-alert :closable="false" class="mb-3 col-12" title="💡 Info" type="success">
        <p><b>Custom Contact</b> setting is where you can add field for update data contact based on what you need. You can add custom field maximal 10 field and you cannot delete field that has data on it.</p>
        <br/>
      </el-alert>
      <el-form label-width="120px" label-position="left">
        <el-form-item v-for="(v, idx) in list_field" :key="idx" width="100px">
          <span class="font-weight-bold" slot="label">{{ v.key ? upperCaseFirst(v.key) : upperCaseFirst(`field_${(idx+1)}`) }}</span>
          <b-row>
            <b-col md=8>
              <el-input placeholder="Label for the field. Example: Email" v-model="v.label" />
            </b-col>
            <b-col md=4>
              <b-row>
                <el-button v-if="idx !== 0" type="danger" size="small" :plain="v.key ? true : false" @click="removeCustomField(idx)" icon="el-icon-delete">
                </el-button>
                <el-button v-if="list_field.length < 10 && (idx+1) == list_field.length" type="primary" size="small" plain @click="addCustomField">
                  Add field
                </el-button>
              </b-row>
            </b-col>
          </b-row>
        </el-form-item>
      </el-form>
      <div align="right">
        <el-button @click="updateCustomContact" :loading="loading.custom_contact" size="small"
          class="btn-primary" align="right">Update</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { upperFirst, isEmpty, cloneDeep } from 'lodash';
import contacts from '../../../api/contacts';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'CustomContact',
  props: {
    workspace_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: {
        setting: true,
        update_bot: false,
        update_work_hour: false,
        auto_reminder: false,
        custom_contact: false,
      },
      loaderStack: 0,
      loader: null,
      list_field: [],
      work_hour: {
        enabled: false,
        config: [],
        message: '',
      },
      search: '',
    };
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadData() {
      this.$parent.loading.setting = true;
      const response = await contacts.get_field({ workspace_id: this.workspace_id });
      this.hideLoader();
      this.$parent.loading.setting = false;
      await popupErrorMessages(response);
      console.log(response.data);
      if (!isEmpty(response.data)) {
        this.list_field = response.data.fields;
      } else {
        this.list_field = [{
          key: 'field_1',
          label: '',
        }];
      }
    },
    async updateCustomContact() {
      this.$parent.loading.custom_contact = true;
      const data_update = cloneDeep(this.list_field);
      const response = await contacts.update_contact_field(
        this.workspace_id,
        { custom_fields: data_update },
      );
      this.hideLoader();
      this.$parent.loading.custom_contact = false;
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: this.$t('settings.success.edit'),
      });
      this.loadWorkHour();
    },
    addCustomField() {
      if (this.list_field.length < 10) {
        this.list_field.push({
          label: '',
        });
      }
    },
    async removeCustomField(idx) {
      this.loading.custom_contact = true;
      const response = await contacts.check_field({
        workspace_id: this.workspace_id,
        key: (idx + 1),
      });
      this.hideLoader();
      this.loading.custom_contact = false;
      await popupErrorMessages(response);
      if (!response.data.error) {
        this.list_field.splice(idx, 1);
      }
    },
  },
};
</script>
<style>
.el-switch__label.is-active {
  color: #10b759 !important;
}
.el-date-editor .el-range-separator {
  width: 15%;
}
.el-switch__label {
  color: #BDC3C7;
}
</style>
